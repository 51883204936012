<!--
 * @Description: 需求列表-查看报价企业
 * @Author:
 * @Date: 2025-03-28
 * @LastEditors:
-->
<template>
  <DrawerView :visible="visible" :width="960" :title="'报价企业'" @cancel="handleCancel" :show-slot-title="true">
    <div class="card-info-box">
      <a-table
        :columns="columns"
        :loading="loading"
        :data-source="dataSource"
        :pagination="false"
        style="margin-bottom: 16px"
        :scroll="{ x: 'max-content' }"
      >
        <template slot="quoteSource" slot-scope="text, record">
         <span v-if="text==1">主动报价</span>
          <span v-else-if="text==2">邀请报价</span>
        </template>
        <template slot="lastModified" slot-scope="text, record">
          <div>
            {{ (text && text.substring(0, 10)) || '' }}
          </div>
        </template>
        <template slot="status" slot-scope="text, record">
           <span class="table-status-btn" :class="quoteStyle[record.status + '']">
            {{ mainOptions[text] || '' }}
          </span>
        </template>
        <!-- quotesCount -->
        <template slot="operation" slot-scope="text, record">
          <a @click="handleSwitchTab(record)" v-if="record.status==3" disabled="" >
            报价详情</a>
          <a @click="handleSwitchTab(record)" v-else >
            报价详情</a>
        </template>

      </a-table>
      <ZPagination
        :total="pageNation.total"
        :current="pageNation.pageNum"
        :pageSize="pageNation.pageSize"
        @handlePageChange="handleChangePage"
      />
    </div>
    <template v-slot:btnArea>
      <a-button @click="handleCancel" style="color: rgba(0, 0, 0, 0.65)">取消</a-button>
    </template>
  </DrawerView>
</template>

<script>
import DrawerView from '@/components/plugins/drawerView.vue'
import { mapGetters } from 'vuex'
import ZPagination from '@/components/plugins/ZPagination.vue'

export default {
  name: 'uploadContract',
  components: {
    ZPagination,
    DrawerView
  },
  inject: ['handleReloadData'],
  props: {
    orderNo: {
      type: String,
      default: ''
    },
    tradeIdentity: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      mainOptions:
        {
          3:'待报价',
          0:'已报价',
          1:'中标',
          2:'未中标',
        '-1':'已取消',
        }   ,
      quoteStyle: {
        0: 'status_4',
        1: 'status_6',
        2: 'status_2',
        3: 'status_8',
        '-1': 'status_10',
      },
      showDig1: false,
      showDig2: false,
      changeTab: '1',
      visible: false,
      loading: false,
      isUpload: false,
      uploadFiles: [],
      basicInfo: {},
      activeKey: '1',
      codeUrl: '异常',
      contractInfo: {},
      looperTimer: null,
      columns: [
        {
          title: '报价企业',
          dataIndex: 'businessName',
          key: 'businessName',
          scopedSlots: { customRender: 'businessName' },
          width: 200
        },
        {
          title: '报价金额（元）',
          dataIndex: 'totalAmount',
          key: 'totalAmount',
          scopedSlots: { customRender: 'totalAmount' },
          width: 120
        },
        {
          title: '报价方式',
          dataIndex: 'quoteSource',
          key: 'quoteSource',
          scopedSlots: { customRender: 'quoteSource' },
          width: 100
        },
        {
          title: '报价状态',
          dataIndex: 'status',
          key: 'status',
          scopedSlots: { customRender: 'status' },
          width: 130
        },
        {
          title: '报价时间',
          dataIndex: 'lastModified',
          key: 'lastModified',
          scopedSlots: { customRender: 'lastModified' },
          width: 120
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
          width: 120,
          fixed: 'right'
        }
      ],
      pageNation: {
        pageNum: 1,
        pageSize: 20,
        total: 0
      },
      validityDateRange: [],
      dataSource: []
    }
  },
  methods: {
    ...mapGetters(['setUserInfo']),
    handleOpen(item) {
      this.dataSource=item
      this.visible = true
    },
    handleCancel() {
      this.visible = false
      this.loading = false
      this.activeKey = '1'
    },
    handleSwitchTab(item) {
      const { quoteId, purchaseId, businessId, status } = item
      this.$router.push(
        `/quotePriceManagement/quotePriceInfoView?purchaseId=${ purchaseId }&quoteId=${ quoteId }&isDetail=${
          status == 0 ? '2' : '1'
        }&businessId=${ businessId }`
      )
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep .ant-tabs-tab {
  padding: 12px 0 20px;
  font-size: 16px;
}

::v-deep .ant-alert-info {
  border: none;
  background: rgba(255, 125, 47, 0.08);
  border-radius: 4px;
  height: 40px;

  &.success-alert {
    background: #e6faf3;
  }

  &.error-alert {
    background: #ee42611a;
  }

  .info-icon {
    width: 17px;
    margin-top: -9px;
  }

  .ant-alert-message {
    color: #131212;
  }
}

.uploadCover {
  margin-top: 25px;
  width: 752px;
  min-height: 244px;
  background: #f9f9f9;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .endTitle {
    margin-top: -15px;
    .flexLayout();
    font-size: 12px;
    justify-content: center;
    color: #8d8e99;
    margin-top: 8px;
  }
}

.ant-form-item-label > label {
  color: #000;
}

.user-area {
  display: flex;
  align-items: center;
  grid-gap: 0 15px;
  margin-top: 23px;
  margin-bottom: 15px;

  &-item {
    width: 364px;
    height: 84px;
    background: #f4f5f7;
    border: 1px solid #e8e9eb;
    border-radius: 4px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 16px;
    box-sizing: border-box;

    .avatar {
      width: 52px;
      margin-right: 8px;
    }

    .info-box {
      flex: 1 0 0;
      min-width: 0;
      height: 52px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .name {
        color: #131212;
        font-family: PingFang SC;
        font-size: 14px;
        font-weight: 600;
        line-height: 22px;
      }

      .signType {
        width: 100%;
        display: flex;
        align-items: center;
        //styleName: 常规/正文1 body_md_regular;
        font-family: PingFang SC;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        color: #5e5e66;
      }
    }

    img.tag {
      width: 52px;
    }
  }
}

::v-deep .ant-tabs-top-bar {
  margin-bottom: 0;
}

.online-sign {
  display: flex;
  align-items: center;
  height: calc(100vh - 300px);
  // min-height: 316px;
  background: #f7f8fa;
  grid-gap: 0 8px;

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 32px;
  }

  .card_1 {
    width: 336px;
    height: 100%;
    background: #fff;
  }

  .card_2 {
    flex: 1 0 0;
    min-width: 0;
    height: 100%;
    background: #fff;
  }

  .title {
    color: #222226;
    font-family: PingFang SC;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
    margin-bottom: 4px;
  }

  .sub-title {
    color: #999;
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.6px;
    text-align: left;
    margin-bottom: 16px;
  }

  .action-btn {
    width: 132px;
    height: 38px;
  }

  .code {
    width: 160px;
    height: 160px;
  }
}

.info-card {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
  display: flex;
  align-items: center;

  img {
    width: 21px;
    height: 21px;
  }

  span {
    margin-left: 10px;
  }
}
</style>
