import { render, staticRenderFns } from "./quoteTable.vue?vue&type=template&id=2c52add4&scoped=true"
import script from "./quoteTable.vue?vue&type=script&lang=js"
export * from "./quoteTable.vue?vue&type=script&lang=js"
import style0 from "./quoteTable.vue?vue&type=style&index=0&id=2c52add4&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c52add4",
  null
  
)

export default component.exports